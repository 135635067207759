import LazyImage from "./LazyImage";
import { React, useState, useEffect, useRef } from "react";

const HomeSection = () => {
  return (
    <section className="firstScreen">
      <div className="container">
        <LazyImage
          className="logo"
          src={process.env.PUBLIC_URL + "/img/logo.svg"}
          alt=""
        />
        <h1>
          Группа компаний <br /> МАРИЭЛТ
        </h1>

        <p>
          Приглашаем к сотрудничеству <br /> опытных агентов и команды
        </p>

        <a className="text" href="#secondSection">
          Узнай, почему работа с нами – <br /> это перспективно и выгодно
        </a>

        <a href="#form" className="button">
          Оставить заявку
        </a>
      </div>
    </section>
  );
};

export default HomeSection;
