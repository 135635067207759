import { React, useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import InputMask from "react-input-mask";

const FormSection = () => {
  const [nameInput, changeNameInput] = useState("");
  const [telInput, changeTelInput] = useState("");
  const rightSectionRef = useRef(null);
  const formSectionRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (rightSectionRef.current && formSectionRef.current) {
      rightSectionRef.current.style.height = `${formSectionRef.current.offsetHeight}px`;
    }
  }, []);

  const options = [
    { value: "Продать квартиру", label: "Продать квартиру" },
    {
      value: "Продать загородную недвижимость",
      label: "Продать загородную недвижимость",
    },
    { value: "Интересует работа", label: "Интересует работа" },
    {
      value: "Продать / сдать в аренду коммерческий объект",
      label: "Продать / сдать в аренду коммерческий объект",
    },
    {
      value: "Страхование / Подбор / Сопровождение",
      label: "Страхование / Подбор / Сопровождение",
    },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const [isOpenError, setIsOpenError] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);

  function sendForm(event) {
    event.preventDefault();
    const TOKEN = `7334631171:AAFY7EWFxFE9eFUOqNiLqUSEeYa054RaHWU`;
    const CHAT_ID = `-4559771300`;

    const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    let message = `<b>Новая заявка с лендинга Marielt</b>\n`;
    message += `🧑🏻Клиент\n`;
    message += `<b>Имя: </b>${nameInput}\n`;
    message += `<b>Номер: </b>${telInput}\n`;

    try {
      axios
        .post(URI_API, {
          chat_id: CHAT_ID,
          parse_mode: "html",
          text: message,
        })
        .then(() => {
          setIsOpenSuccess(true);
          changeNameInput("");
          changeTelInput("");

          setTimeout(() => {
            setIsOpenSuccess(false);
          }, 3000);
        })
        .catch(() => {
          setIsOpenError(true);
          setTimeout(() => {
            setIsOpenError(false);
          }, 3000);
        });
    } catch (error) {
      setIsOpenError(true);
    }
  }

  return (
    <section id="form" className="form" ref={formSectionRef}>
      <div className={`errorMessage message ${isOpenError ? "active" : ""}`}>
        <p>При отправке сообщения произошла ошибка</p>
      </div>
      <div
        className={`sucsesfullMessage message ${isOpenSuccess ? "active" : ""}`}
      >
        <p>Сообщение успешно отправлено</p>
      </div>
      <div className="container">
        <div className="left">
          <h2>
            Узнайте про реферальную <br /> систему бонусов
          </h2>
          <p>
            Свяжитесь с нами и мы расскажем <br /> про все выгоды работы в
            Мариэлт
          </p>
        </div>
        <div className="right" ref={rightSectionRef}>
          <form ref={formRef}>
            <h3>Оставьте заявку</h3>
            <p>Наши специалисты ответят на все ваши вопросы</p>

            <div className="inputContainer">
              <input
                type="text"
                name="name"
                placeholder="Имя*"
                required
                value={nameInput}
                onChange={(e) => changeNameInput(e.target.value)}
              />
              <InputMask
                mask="+375 (99) 999-99-99"
                value={telInput}
                onChange={(e) => changeTelInput(e.target.value)}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type="tel"
                    placeholder="+375 (xx) xxx-xx-xx"
                    name="tel"
                    required
                  />
                )}
              </InputMask>

              <div className="row">
                <input type="checkbox" name="agree" required id="" />
                <p>
                  Я согласен(а) с обработкой <br /> моих персональных данных
                </p>
              </div>

              <button onClick={(event) => sendForm(event)}>
                Оставить заявку
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default FormSection;
