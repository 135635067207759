import { React, useState, useEffect, useRef } from "react";
import LazyImage from "./LazyImage";

const QuestionSection = () => {
  return (
    <section className="question">
      <div className="row">
        <div className="block">
          <div className="inRow">
            <p className="light">01</p>
          </div>

          <h3>А что с изменениями законодательства?</h3>

          <p className="light">
            Мы проведем курсы и поможем пройти переаттестацию на риэлтора!
          </p>
        </div>
        <div className="block">
          <div className="inRow">
            <p>02</p>
          </div>

          <h3>А что если я уже менеджер с командой?</h3>
          <p className="light">
            Приходи с командой, обучись нашим бизнес-процессам и регламентам
            работы и мы откроем вам собственный филиал, который обеспечим всем
            необходимым, включая новых сотрудников.
          </p>
        </div>
      </div>

      <div className="block100">
        <div className="container">
          <div className="left">
            <div class="inRow_mobile mobile">
              <p>03</p>
            </div>
            <h3>А как же развитие личного бренда?</h3>
            <p>
              В 2025 году запускаем{" "}
              <span className="bold">школу по маркетингу для агентов</span>{" "}
              и поможем вам развивать свои соцсети и привлекать лидов через
              интернет.
            </p>
            <p>
              А еще мы создаем свою студию для записи видео-роликов. Брендируем
              машины магнитами за счет компании.
            </p>

            <a href="#">Успей вписаться в поток!</a>
          </div>
          <div className="right">
            <div class="inRow">
              <p className="light">03</p>
            </div>
            <LazyImage src={process.env.PUBLIC_URL + "/img/auto.png"} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionSection;
